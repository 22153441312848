// 系统管理相关请求
import request from '@/request/request'
// 请求树形数据的接口
export const MenuTreeApi = () => request.get('/frame-web/admin/sys/menu/tree');

// 新增菜单接口
export const MenuAddApi = (params) => request.post('/frame-web/admin/sys/menu/add', params);
// 菜单列表接口 
export const MenuListApi = () => request.get('/frame-web/admin/sys/menu/tree/copy');
// 删除菜单接口
export const MenuDeleteApi = (params) => request.post(`/frame-web/admin/sys/menu/delete/${params.id}`, params);
// 更新菜单接口
export const MenuUpdateApi = (params) => request.post(`/frame-web/admin/sys/menu/update/${params.id}`, params);
// 菜单详情接口
export const MenuDetailApi = (params) => request.get(`/frame-web/admin/sys/menu/detail/${params.id}`, { params });


// 全局常量列表
export const GetConstantResultsApi = (params) => request.get(`/frame-web/admin/config/getConstantResults`, { params });
// 修改全局常量
export const UpdConstantResultApi = (params) => request.post(`/frame-web/admin/config/updConstantResult`,  params );


// 获取操作日志
export const GetOperationsApi = (params) => request.post(`/frame-web/admin/log/exception/getOperations`, params);
// 操作日志详情
export const GetOperationInfoApi = (params) => request.post(`/frame-web/admin/log/exception/getOperationInfo/${params.id}`, params);


// 异常日志搜索查询
export const LogExceptionListApi = (params) => request.post(`/frame-web/admin/log/exception/list`, params);
// 异常日志详情
export const GetExceptionLogApi = (params) => request.get(`/frame-web/admin/log/exception/getExceptionLog`, { params });
// 异常日志初始化
export const SaveBatchErrorLogApi = (params) => request.get(`/frame-web/admin/log/exception/saveBatchErrorLog`, { params });
// 异常日志删除
export const ExceptionDellAllApi = (params) => request.get(`/frame-web/admin/log/exception/dellAll`, { params });


// 视频转码 批量更新视频大小
export const UpdCourseLessonsFileSizeApi = (params) => request.get(`/frame-web/admin/task/course/updCourseLessonsFileSize`, { params });
// 执行视频转码任务
export const CreateMediaJobsApi = (params) => request.get(`/frame-web/admin/task/course/createMediaJobs`, { params });
// 更新转码状态
export const DescribeMediaJobApi = (params) => request.get(`/frame-web/admin/task/course/describeMediaJob`, { params });
// 获取会员课程列表
export const GetHomeCourseInfoResultbApi = (params) => request.get(`/frame-web/admin/task/course/getHomeCourseInfoResult`, { params });
// 获取课节详情接口
export const GetCourseLessonsApi = (params) => request.get(`/frame-web/admin/task/course/getCourseLessons`, { params });
